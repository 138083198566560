import React, { useMemo, useState, useEffect, lazy, Suspense } from 'react';
import { handleRefresh } from './RefreshUtils'; 
import {TalentCard} from './types';
import { useTranslation } from 'react-i18next';
import './i18n'; // Import the i18n configuration

const NotificationLazy = lazy(() => import('./notifications'));

interface TokenProps {
  card: TalentCard;
}



const TokenButton: React.FC<TokenProps> = ({ card}) => {
  const [isActive, setIsActive] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [notification, setNotification] = useState<{ message: string; backgroundColor?: string } | null>(null);
  const [isActivable, setIsActivable] = useState(false);

  const triggerNotificationWithMessage = (message: string, backgroundColor?: string) => {
    setNotification({ message, backgroundColor });
  };

  const closeNotification = () => {
    setNotification(null);
  };

  const [indexLanguage, setIndexLanguage] = useState('');

  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`) 
    ? `https://${stagingKey}.peaxel.me` 
    : 'https://peaxel.me';

  useEffect(() => {
    const storedLanguage = localStorage.getItem('pxlLanguage') || 'en'; // Default to 'en' if not found
    setIndexLanguage(storedLanguage);
  }, []);

  useEffect(() => {
    checkTokenStatus();
  }, []);

  const checkTokenStatus = async () => {
    try {
      setIsActive(card.lineup.toString() === '1');
      setIsActivable( parseInt(card.current_order) < 10 );

    } catch (error) {
      console.error('Error checking token status:', error);
      setErrorMessage('Failed to fetch token status');
    }
  };

  const handleClick = async () => {
    try {
      const response = await fetch(`${linkUrl}/wp-json/custom/v1/talent-card-lineup-prop/?token_address=${card.token_address}&token_id=${card.token_id}`);
      if (!response.ok) {
        throw new Error('Failed to update token status');
      }
      const data = await response.json();
      console.log('response',response.json);
      if (data.status == 0) {
      
        setIsActive(false);
        setIsActivable( parseInt(card.current_order) < 10 );
        console.log('data.status',data.status);
        console.log('setIsActive(false);',setIsActive(false));
        triggerNotificationWithMessage('Lineup Talents Limit Reached', '#D31148');
      } else if (data.status == 1) {
        setIsActive(true);
        setIsActivable( parseInt(card.current_order) < 10 );
        triggerNotificationWithMessage('Added to the Lineup', '#c2db2e');
        console.log('data.status',data.status);
        console.log('setIsActive(true);',setIsActive(true));
      } else if (data.status == 2) {
        setIsActive(false);
        setIsActivable( parseInt(card.current_order) < 10 );
        triggerNotificationWithMessage('Removed From the Lineup', '#4C3316');
        console.log('data.status',data.status);
        console.log('setIsActive(false);',setIsActive(false));
      }

      handleRefresh();
     
    } catch (error) {
      console.error('Error updating token status:', error);
      setErrorMessage('Failed to update token status');
    }

  };

  return (
    <div className={`fusionDiv ${isActivable ? 'activable' : 'noactivable'}`}>
      <span
        //id={`fuse-icon-${card.token_id}`}
        className={`icon-container ${isActive ? 'active' : 'inactive'}`}
        onClick={handleClick}
      >
        <i className='fa-solid fa-bolt-lightning'></i>&nbsp;Lineup
      </span>

      {notification && (
        <Suspense fallback={<div>Loading...</div>}>
        <NotificationLazy
          message={notification.message}
          onClose={closeNotification}
          backgroundColor={notification.backgroundColor}
        /></Suspense>
      )}
    </div>
  );
};

export default TokenButton;
